import { Alert, AlertTitle } from "@mui/material";
import * as React from 'react';

type Props = {
    title: string;
    message: string;
}


const Warn_Banner: React.FC<Props> = ({
    title,
    message
}) => {
    return (
        <Alert sx={{ margin: 2 }} severity="warning">
            <>
                <AlertTitle>{title}</AlertTitle>
                {
                    message
                }
            </>
        </Alert>
    );
}

export default Warn_Banner;