import React from "react";
import { case_fan, case_fan_back, case_fan_front } from "../../images";

const CASE_FAN_SCALE = 140;

const base_style = {
  zIndex: 2,
  position: "absolute" as const,
};

const back_style = {
  position: "absolute" as const,
};

const front_style = {
  position: "absolute" as const,
  zIndex: 4,
};

const default_spinning_part_style = {
  position: "absolute" as const,
  zIndex: 3,
};

const Icon_Case_Fan = () => {
  return (
    <>
      <div style={base_style}>
        <img style={back_style} src={case_fan_back} width={CASE_FAN_SCALE} />
        <img
          style={default_spinning_part_style}
          src={case_fan}
          width={CASE_FAN_SCALE}
        />
        <img style={front_style} src={case_fan_front} width={CASE_FAN_SCALE} />
      </div>
    </>
  );
};

export default Icon_Case_Fan;
