import React from "react";
import { operating_system } from "../../images";

const default_operating_system_container_style = {
  position: "absolute" as const,
  width: "100%",
};

const Icon_Operating_System = () => {
  return (
    <>
      <div style={default_operating_system_container_style}>
        <img src={operating_system} />
      </div>
    </>
  );
};

export default Icon_Operating_System;
