import React from "react";
import { motherboard } from "../../images";

const default_mobo_container_style = {};

const Icon_Motherboard = () => {
  return (
    <>
      <div style={default_mobo_container_style}>
        <img src={motherboard} />
      </div>
    </>
  );
};

export default Icon_Motherboard;
