import React from "react";
import { psu } from "../../images";

const default_psu_container_style = {
  position: "absolute" as const,
  width: "100%",
};

const Icon_Psu = () => {
  return (
    <>
      <div style={default_psu_container_style}>
        <img src={psu} />
      </div>
    </>
  );
};

export default Icon_Psu;
